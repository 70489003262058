
  import Vue from "vue";

  export default Vue.extend({
    name: "pwa-minigame-header",
    components: {
      PwaGamesSlider: () => import("@/components/game/PwaGamesSlider.vue"),
    },
    props: {
      gameData: {
        type: Object,
        required: true,
      },
      minigames: {
        type: Array,
        required: false,
        default: null,
      },
    },
  });
